import './bootstrap';
import AOS from 'aos';
import SmoothScroll from 'smooth-scroll';

import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

import './components/toastify';
import 'bootstrap';

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true,
});

window.onload = function () {
    AOS.init({
        offset: 0,
        // duration: 1000,
        delay: 0,
        once: true,
        anchorPlacement: 'top-center',
    });
};

new Swiper('.swiper', {
    // slidesPerView: 5,
    //spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        //	clickable: true,
    },
    autoplay: {
        delay: 3000
    },
    speed: 1000,
    modules: [Autoplay, Pagination],
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 30,
        }
    },
});